import Dropdown from 'react-bootstrap/Dropdown';

const Countries = () => (<>
    <Dropdown.Item eventKey="Afganistan" value="Afganistan">Afganistan</Dropdown.Item>
    <Dropdown.Item eventKey="Albania" value="Albania">Albania</Dropdown.Item>
    <Dropdown.Item eventKey="Algeria">Algeria</Dropdown.Item>
    <Dropdown.Item eventKey="Andorra">Andorra</Dropdown.Item>
    <Dropdown.Item eventKey="Angola">Angola</Dropdown.Item>
    <Dropdown.Item eventKey="Anguilla" value="Anguilla">Anguilla</Dropdown.Item>
    <Dropdown.Item eventKey="Ancient" value="Ancients">Ancient</Dropdown.Item>
    <Dropdown.Item eventKey="Antigua &amp; Barbuda" value="Antigua &amp; Barbuda">Antigua &amp; Barbuda</Dropdown.Item>
    <Dropdown.Item eventKey="Argentina" value="Argentina">Argentina</Dropdown.Item>
    <Dropdown.Item eventKey="Armenia" value="Armenia">Armenia</Dropdown.Item>
    <Dropdown.Item eventKey="Aruba" value="Aruba">Aruba</Dropdown.Item>
    <Dropdown.Item eventKey="Australia" value="Australia">Australia</Dropdown.Item>
    <Dropdown.Item eventKey="Austria" value="Austria">Austria</Dropdown.Item>
    <Dropdown.Item eventKey="Azerbaijan" value="Azerbaijan">Azerbaijan</Dropdown.Item>
    <Dropdown.Item eventKey="Bahamas" value="Bahamas">Bahamas</Dropdown.Item>
    <Dropdown.Item eventKey="Bahrain" value="Bahrain">Bahrain</Dropdown.Item>
    <Dropdown.Item eventKey="Bangladesh" value="Bangladesh">Bangladesh</Dropdown.Item>
    <Dropdown.Item eventKey="Barbados" value="Barbados">Barbados</Dropdown.Item>
    <Dropdown.Item eventKey="Belarus" value="Belarus">Belarus</Dropdown.Item>
    <Dropdown.Item eventKey="Belgium" value="Belgium">Belgium</Dropdown.Item>
    <Dropdown.Item eventKey="Belize" value="Belize">Belize</Dropdown.Item>
    <Dropdown.Item eventKey="Benin" value="Benin">Benin</Dropdown.Item>
    <Dropdown.Item eventKey="Bermuda" value="Bermuda">Bermuda</Dropdown.Item>
    <Dropdown.Item eventKey="Bhutan" value="Bhutan">Bhutan</Dropdown.Item>
    <Dropdown.Item eventKey="Bolivia" value="Bolivia">Bolivia</Dropdown.Item>
    <Dropdown.Item eventKey="Bonaire" value="Bonaire">Bonaire</Dropdown.Item>
    <Dropdown.Item eventKey="Bosnia &amp; Herzegovina" value="Bosnia &amp; Herzegovina">Bosnia &amp; Herzegovina</Dropdown.Item>
    <Dropdown.Item eventKey="Botswana" value="Botswana">Botswana</Dropdown.Item>
    <Dropdown.Item eventKey="Brazil" value="Brazil">Brazil</Dropdown.Item>
    <Dropdown.Item eventKey="British Indian Ocean Ter" value="British Indian Ocean Ter">British Indian Ocean Ter</Dropdown.Item>
    <Dropdown.Item eventKey="Brunei" value="Brunei">Brunei</Dropdown.Item>
    <Dropdown.Item eventKey="Bulgaria" value="Bulgaria">Bulgaria</Dropdown.Item>
    <Dropdown.Item eventKey="Burkina Faso" value="Burkina Faso">Burkina Faso</Dropdown.Item>
    <Dropdown.Item eventKey="Burma" value="Burma">Burma</Dropdown.Item>
    <Dropdown.Item eventKey="Burundi" value="Burundi">Burundi</Dropdown.Item>
    <Dropdown.Item eventKey="Cambodia" value="Cambodia">Cambodia</Dropdown.Item>
    <Dropdown.Item eventKey="Cameroon" value="Cameroon">Cameroon</Dropdown.Item>
    <Dropdown.Item eventKey="Canada" value="Canada">Canada</Dropdown.Item>
    <Dropdown.Item eventKey="Canary Islands" value="Canary Islands">Canary Islands</Dropdown.Item>
    <Dropdown.Item eventKey="Cape Verde" value="Cape Verde">Cape Verde</Dropdown.Item>
    <Dropdown.Item eventKey="Cayman Islands" value="Cayman Islands">Cayman Islands</Dropdown.Item>
    <Dropdown.Item eventKey="Central African Republic" value="Central African Republic">Central African Republic</Dropdown.Item>
    <Dropdown.Item eventKey="Chad" value="Chad">Chad</Dropdown.Item>
    <Dropdown.Item eventKey="Channel Islands" value="Channel Islands">Channel Islands</Dropdown.Item>
    <Dropdown.Item eventKey="Chile" value="Chile">Chile</Dropdown.Item>
    <Dropdown.Item eventKey="China" value="China">China</Dropdown.Item>
    <Dropdown.Item eventKey="Christmas Island" value="Christmas Island">Christmas Island</Dropdown.Item>
    <Dropdown.Item eventKey="Cocos Island" value="Cocos Island">Cocos Island</Dropdown.Item>
    <Dropdown.Item eventKey="Colombia" value="Colombia">Colombia</Dropdown.Item>
    <Dropdown.Item eventKey="Comoros" value="Comoros">Comoros</Dropdown.Item>
    <Dropdown.Item eventKey="Congo" value="Congo">Congo</Dropdown.Item>
    <Dropdown.Item eventKey="Cook Islands" value="Cook Islands">Cook Islands</Dropdown.Item>
    <Dropdown.Item eventKey="Costa Rica" value="Costa Rica">Costa Rica</Dropdown.Item>
    <Dropdown.Item eventKey="Cote DIvoire" value="Cote DIvoire">Cote D Ivoire</Dropdown.Item>
    <Dropdown.Item eventKey="Croatia" value="Croatia">Croatia</Dropdown.Item>
    <Dropdown.Item eventKey="Cuba" value="Cuba">Cuba</Dropdown.Item>
    <Dropdown.Item eventKey="Curaco" value="Curaco">Curacao</Dropdown.Item>
    <Dropdown.Item eventKey="Cyprus" value="Cyprus">Cyprus</Dropdown.Item>
    <Dropdown.Item eventKey="Czech Republic" value="Czech Republic">Czech Republic</Dropdown.Item>
    <Dropdown.Item eventKey="Denmark" value="Denmark">Denmark</Dropdown.Item>
    <Dropdown.Item eventKey="Djibouti" value="Djibouti">Djibouti</Dropdown.Item>
    <Dropdown.Item eventKey="Dominica" value="Dominica">Dominica</Dropdown.Item>
    <Dropdown.Item eventKey="Dominican Republic" value="Dominican Republic">Dominican Republic</Dropdown.Item>
    <Dropdown.Item eventKey="East Timor" value="East Timor">East Timor</Dropdown.Item>
    <Dropdown.Item eventKey="Ecuador" value="Ecuador">Ecuador</Dropdown.Item>
    <Dropdown.Item eventKey="Egypt" value="Egypt">Egypt</Dropdown.Item>
    <Dropdown.Item eventKey="El Salvador" value="El Salvador">El Salvador</Dropdown.Item>
    <Dropdown.Item eventKey="Equatorial Guinea" value="Equatorial Guinea">Equatorial Guinea</Dropdown.Item>
    <Dropdown.Item eventKey="Eritrea" value="Eritrea">Eritrea</Dropdown.Item>
    <Dropdown.Item eventKey="Estonia" value="Estonia">Estonia</Dropdown.Item>
    <Dropdown.Item eventKey="Ethiopia" value="Ethiopia">Ethiopia</Dropdown.Item>
    <Dropdown.Item eventKey="Falkland Islands" value="Falkland Islands">Falkland Islands</Dropdown.Item>
    <Dropdown.Item eventKey="Faroe Islands" value="Faroe Islands">Faroe Islands</Dropdown.Item>
    <Dropdown.Item eventKey="Fiji" value="Fiji">Fiji</Dropdown.Item>
    <Dropdown.Item eventKey="Finland" value="Finland">Finland</Dropdown.Item>
    <Dropdown.Item eventKey="France" value="France">France</Dropdown.Item>
    <Dropdown.Item eventKey="French Guiana" value="French Guiana">French Guiana</Dropdown.Item>
    <Dropdown.Item eventKey="French Polynesia" value="French Polynesia">French Polynesia</Dropdown.Item>
    <Dropdown.Item eventKey="French Southern Ter" value="French Southern Ter">French Southern Ter</Dropdown.Item>
    <Dropdown.Item eventKey="Gabon" value="Gabon">Gabon</Dropdown.Item>
    <Dropdown.Item eventKey="Gambia" value="Gambia">Gambia</Dropdown.Item>
    <Dropdown.Item eventKey="Georgia" value="Georgia">Georgia</Dropdown.Item>
    <Dropdown.Item eventKey="Germany" value="Germany">Germany</Dropdown.Item>
    <Dropdown.Item eventKey="Ghana" value="Ghana">Ghana</Dropdown.Item>
    <Dropdown.Item eventKey="Gibraltar" value="Gibraltar">Gibraltar</Dropdown.Item>
    <Dropdown.Item eventKey="Great Britain" value="Great Britain">Great Britain</Dropdown.Item>
    <Dropdown.Item eventKey="Greece" value="Greece">Greece</Dropdown.Item>
    <Dropdown.Item eventKey="Greenland" value="Greenland">Greenland</Dropdown.Item>
    <Dropdown.Item eventKey="Grenada" value="Grenada">Grenada</Dropdown.Item>
    <Dropdown.Item eventKey="Guadeloupe" value="Guadeloupe">Guadeloupe</Dropdown.Item>
    <Dropdown.Item eventKey="Guam" value="Guam">Guam</Dropdown.Item>
    <Dropdown.Item eventKey="Guatemala" value="Guatemala">Guatemala</Dropdown.Item>
    <Dropdown.Item eventKey="Guinea" value="Guinea">Guinea</Dropdown.Item>
    <Dropdown.Item eventKey="Guyana" value="Guyana">Guyana</Dropdown.Item>
    <Dropdown.Item eventKey="Haiti" value="Haiti">Haiti</Dropdown.Item>
    <Dropdown.Item eventKey="Hawaii" value="Hawaii">Hawaii</Dropdown.Item>
    <Dropdown.Item eventKey="Honduras" value="Honduras">Honduras</Dropdown.Item>
    <Dropdown.Item eventKey="Hong Kong" value="Hong Kong">Hong Kong</Dropdown.Item>
    <Dropdown.Item eventKey="Hungary" value="Hungary">Hungary</Dropdown.Item>
    <Dropdown.Item eventKey="Iceland" value="Iceland">Iceland</Dropdown.Item>
    <Dropdown.Item eventKey="India" value="India">India</Dropdown.Item>
    <Dropdown.Item eventKey="Indonesia" value="Indonesia">Indonesia</Dropdown.Item>
    <Dropdown.Item eventKey="Iran" value="Iran">Iran</Dropdown.Item>
    <Dropdown.Item eventKey="Iraq" value="Iraq">Iraq</Dropdown.Item>
    <Dropdown.Item eventKey="Ireland" value="Ireland">Ireland</Dropdown.Item>
    <Dropdown.Item eventKey="Isle of Man" value="Isle of Man">Isle of Man</Dropdown.Item>
    <Dropdown.Item eventKey="Israel" value="Israel">Israel</Dropdown.Item>
    <Dropdown.Item eventKey="Italy" value="Italy">Italy</Dropdown.Item>
    <Dropdown.Item eventKey="Jamaica" value="Jamaica">Jamaica</Dropdown.Item>
    <Dropdown.Item eventKey="Japan" value="Japan">Japan</Dropdown.Item>
    <Dropdown.Item eventKey="Jordan" value="Jordan">Jordan</Dropdown.Item>
    <Dropdown.Item eventKey="Kazakhstan" value="Kazakhstan">Kazakhstan</Dropdown.Item>
    <Dropdown.Item eventKey="Kenya" value="Kenya">Kenya</Dropdown.Item>
    <Dropdown.Item eventKey="Kiribati" value="Kiribati">Kiribati</Dropdown.Item>
    <Dropdown.Item eventKey="Korea" value="Korea">Korea</Dropdown.Item>
    <Dropdown.Item eventKey="Korea North" value="Korea North">Korea North</Dropdown.Item>
    <Dropdown.Item eventKey="Korea Sout" value="Korea Sout">Korea South</Dropdown.Item>
    <Dropdown.Item eventKey="Kuwait" value="Kuwait">Kuwait</Dropdown.Item>
    <Dropdown.Item eventKey="Kyrgyzstan" value="Kyrgyzstan">Kyrgyzstan</Dropdown.Item>
    <Dropdown.Item eventKey="Laos" value="Laos">Laos</Dropdown.Item>
    <Dropdown.Item eventKey="Latvia" value="Latvia">Latvia</Dropdown.Item>
    <Dropdown.Item eventKey="Lebanon" value="Lebanon">Lebanon</Dropdown.Item>
    <Dropdown.Item eventKey="Lesotho" value="Lesotho">Lesotho</Dropdown.Item>
    <Dropdown.Item eventKey="Liberia" value="Liberia">Liberia</Dropdown.Item>
    <Dropdown.Item eventKey="Libya" value="Libya">Libya</Dropdown.Item>
    <Dropdown.Item eventKey="Liechtenstein" value="Liechtenstein">Liechtenstein</Dropdown.Item>
    <Dropdown.Item eventKey="Lithuania" value="Lithuania">Lithuania</Dropdown.Item>
    <Dropdown.Item eventKey="Luxembourg" value="Luxembourg">Luxembourg</Dropdown.Item>
    <Dropdown.Item eventKey="Macau" value="Macau">Macau</Dropdown.Item>
    <Dropdown.Item eventKey="Macedonia" value="Macedonia">Macedonia</Dropdown.Item>
    <Dropdown.Item eventKey="Madagascar" value="Madagascar">Madagascar</Dropdown.Item>
    <Dropdown.Item eventKey="Malaysia" value="Malaysia">Malaysia</Dropdown.Item>
    <Dropdown.Item eventKey="Malawi" value="Malawi">Malawi</Dropdown.Item>
    <Dropdown.Item eventKey="Maldives" value="Maldives">Maldives</Dropdown.Item>
    <Dropdown.Item eventKey="Mali" value="Mali">Mali</Dropdown.Item>
    <Dropdown.Item eventKey="Malta" value="Malta">Malta</Dropdown.Item>
    <Dropdown.Item eventKey="Marshall Islands" value="Marshall Islands">Marshall Islands</Dropdown.Item>
    <Dropdown.Item eventKey="Martinique" value="Martinique">Martinique</Dropdown.Item>
    <Dropdown.Item eventKey="Mauritania" value="Mauritania">Mauritania</Dropdown.Item>
    <Dropdown.Item eventKey="Mauritius" value="Mauritius">Mauritius</Dropdown.Item>
    <Dropdown.Item eventKey="Mayotte" value="Mayotte">Mayotte</Dropdown.Item>
    <Dropdown.Item eventKey="Mexico" value="Mexico">Mexico</Dropdown.Item>
    <Dropdown.Item eventKey="Midway Islands" value="Midway Islands">Midway Islands</Dropdown.Item>
    <Dropdown.Item eventKey="Moldova" value="Moldova">Moldova</Dropdown.Item>
    <Dropdown.Item eventKey="Monaco" value="Monaco">Monaco</Dropdown.Item>
    <Dropdown.Item eventKey="Mongolia" value="Mongolia">Mongolia</Dropdown.Item>
    <Dropdown.Item eventKey="Montserrat" value="Montserrat">Montserrat</Dropdown.Item>
    <Dropdown.Item eventKey="Montenegro" value="Montenegro">Montenegro</Dropdown.Item>
    <Dropdown.Item eventKey="Morocco" value="Morocco">Morocco</Dropdown.Item>
    <Dropdown.Item eventKey="Mozambique" value="Mozambique">Mozambique</Dropdown.Item>
    <Dropdown.Item eventKey="Myanmar" value="Myanmar">Myanmar</Dropdown.Item>
    <Dropdown.Item eventKey="Nambia" value="Nambia">Nambia</Dropdown.Item>
    <Dropdown.Item eventKey="Nauru" value="Nauru">Nauru</Dropdown.Item>
    <Dropdown.Item eventKey="Nepal" value="Nepal">Nepal</Dropdown.Item>
    <Dropdown.Item eventKey="Netherland Antilles" value="Netherland Antilles">Netherland Antilles</Dropdown.Item>
    <Dropdown.Item eventKey="Netherlands" value="Netherlands">Netherlands (Holland, Europe)</Dropdown.Item>
    <Dropdown.Item eventKey="Nevis" value="Nevis">Nevis</Dropdown.Item>
    <Dropdown.Item eventKey="New Caledonia" value="New Caledonia">New Caledonia</Dropdown.Item>
    <Dropdown.Item eventKey="New Zealand" value="New Zealand">New Zealand</Dropdown.Item>
    <Dropdown.Item eventKey="Nicaragua" value="Nicaragua">Nicaragua</Dropdown.Item>
    <Dropdown.Item eventKey="Niger" value="Niger">Niger</Dropdown.Item>
    <Dropdown.Item eventKey="Nigeria" value="Nigeria">Nigeria</Dropdown.Item>
    <Dropdown.Item eventKey="Niue" value="Niue">Niue</Dropdown.Item>
    <Dropdown.Item eventKey="Norfolk Island" value="Norfolk Island">Norfolk Island</Dropdown.Item>
    <Dropdown.Item eventKey="Norway" value="Norway">Norway</Dropdown.Item>
    <Dropdown.Item eventKey="Oman" value="Oman">Oman</Dropdown.Item>
    <Dropdown.Item eventKey="Pakistan" value="Pakistan">Pakistan</Dropdown.Item>
    <Dropdown.Item eventKey="Palau Island" value="Palau Island">Palau Island</Dropdown.Item>
    <Dropdown.Item eventKey="Palestine" value="Palestine">Palestine</Dropdown.Item>
    <Dropdown.Item eventKey="Panama" value="Panama">Panama</Dropdown.Item>
    <Dropdown.Item eventKey="Papua New Guinea" value="Papua New Guinea">Papua New Guinea</Dropdown.Item>
    <Dropdown.Item eventKey="Paraguay" value="Paraguay">Paraguay</Dropdown.Item>
    <Dropdown.Item eventKey="Peru" value="Peru">Peru</Dropdown.Item>
    <Dropdown.Item eventKey="Phillipines" value="Phillipines">Philippines</Dropdown.Item>
    <Dropdown.Item eventKey="Pitcairn Island" value="Pitcairn Island">Pitcairn Island</Dropdown.Item>
    <Dropdown.Item eventKey="Poland" value="Poland">Poland</Dropdown.Item>
    <Dropdown.Item eventKey="Portugal" value="Portugal">Portugal</Dropdown.Item>
    <Dropdown.Item eventKey="Puerto Rico" value="Puerto Rico">Puerto Rico</Dropdown.Item>
    <Dropdown.Item eventKey="Qatar" value="Qatar">Qatar</Dropdown.Item>
    <Dropdown.Item eventKey="Republic of Montenegro" value="Republic of Montenegro">Republic of Montenegro</Dropdown.Item>
    <Dropdown.Item eventKey="Republic of Serbia" value="Republic of Serbia">Republic of Serbia</Dropdown.Item>
    <Dropdown.Item eventKey="Reunion" value="Reunion">Reunion</Dropdown.Item>
    <Dropdown.Item eventKey="Romania" value="Romania">Romania</Dropdown.Item>
    <Dropdown.Item eventKey="Russia" value="Russia">Russia</Dropdown.Item>
    <Dropdown.Item eventKey="Rwanda" value="Rwanda">Rwanda</Dropdown.Item>
    <Dropdown.Item eventKey="St Barthelemy" value="St Barthelemy">St Barthelemy</Dropdown.Item>
    <Dropdown.Item eventKey="St Eustatius" value="St Eustatius">St Eustatius</Dropdown.Item>
    <Dropdown.Item eventKey="St Helena" value="St Helena">St Helena</Dropdown.Item>
    <Dropdown.Item eventKey="St Kitts-Nevis" value="St Kitts-Nevis">St Kitts-Nevis</Dropdown.Item>
    <Dropdown.Item eventKey="St Lucia" value="St Lucia">St Lucia</Dropdown.Item>
    <Dropdown.Item eventKey="St Maarten" value="St Maarten">St Maarten</Dropdown.Item>
    <Dropdown.Item eventKey="St Pierre &amp; Miquelon" value="St Pierre &amp; Miquelon">St Pierre &amp; Miquelon</Dropdown.Item>
    <Dropdown.Item eventKey="St Vincent &amp; Grenadines" value="St Vincent &amp; Grenadines">St Vincent &amp; Grenadines</Dropdown.Item>
    <Dropdown.Item eventKey="Saipan" value="Saipan">Saipan</Dropdown.Item>
    <Dropdown.Item eventKey="Samoa" value="Samoa">Samoa</Dropdown.Item>
    <Dropdown.Item eventKey="Samoa American" value="Samoa American">Samoa American</Dropdown.Item>
    <Dropdown.Item eventKey="San Marino" value="San Marino">San Marino</Dropdown.Item>
    <Dropdown.Item eventKey="Sao Tome &amp; Principe" value="Sao Tome &amp; Principe">Sao Tome &amp; Principe</Dropdown.Item>
    <Dropdown.Item eventKey="Saudi Arabia" value="Saudi Arabia">Saudi Arabia</Dropdown.Item>
    <Dropdown.Item eventKey="Senegal" value="Senegal">Senegal</Dropdown.Item>
    <Dropdown.Item eventKey="Serbia" value="Serbia">Serbia</Dropdown.Item>
    <Dropdown.Item eventKey="Seychelles" value="Seychelles">Seychelles</Dropdown.Item>
    <Dropdown.Item eventKey="Sierra Leone" value="Sierra Leone">Sierra Leone</Dropdown.Item>
    <Dropdown.Item eventKey="Singapore" value="Singapore">Singapore</Dropdown.Item>
    <Dropdown.Item eventKey="Slovakia" value="Slovakia">Slovakia</Dropdown.Item>
    <Dropdown.Item eventKey="Slovenia" value="Slovenia">Slovenia</Dropdown.Item>
    <Dropdown.Item eventKey="Solomon Islands" value="Solomon Islands">Solomon Islands</Dropdown.Item>
    <Dropdown.Item eventKey="Somalia" value="Somalia">Somalia</Dropdown.Item>
    <Dropdown.Item eventKey="South Africa" value="South Africa">South Africa</Dropdown.Item>
    <Dropdown.Item eventKey="Spain" value="Spain">Spain</Dropdown.Item>
    <Dropdown.Item eventKey="Sri Lanka" value="Sri Lanka">Sri Lanka</Dropdown.Item>
    <Dropdown.Item eventKey="Sudan" value="Sudan">Sudan</Dropdown.Item>
    <Dropdown.Item eventKey="Suriname" value="Suriname">Suriname</Dropdown.Item>
    <Dropdown.Item eventKey="Swaziland" value="Swaziland">Swaziland</Dropdown.Item>
    <Dropdown.Item eventKey="Sweden" value="Sweden">Sweden</Dropdown.Item>
    <Dropdown.Item eventKey="Switzerland" value="Switzerland">Switzerland</Dropdown.Item>
    <Dropdown.Item eventKey="Syria" value="Syria">Syria</Dropdown.Item>
    <Dropdown.Item eventKey="Tahiti" value="Tahiti">Tahiti</Dropdown.Item>
    <Dropdown.Item eventKey="Taiwan" value="Taiwan">Taiwan</Dropdown.Item>
    <Dropdown.Item eventKey="Tajikistan" value="Tajikistan">Tajikistan</Dropdown.Item>
    <Dropdown.Item eventKey="Tanzania" value="Tanzania">Tanzania</Dropdown.Item>
    <Dropdown.Item eventKey="Thailand" value="Thailand">Thailand</Dropdown.Item>
    <Dropdown.Item eventKey="Togo" value="Togo">Togo</Dropdown.Item>
    <Dropdown.Item eventKey="Tokelau" value="Tokelau">Tokelau</Dropdown.Item>
    <Dropdown.Item eventKey="Tonga" value="Tonga">Tonga</Dropdown.Item>
    <Dropdown.Item eventKey="Trinidad &amp; Tobago" value="Trinidad &amp; Tobago">Trinidad &amp; Tobago</Dropdown.Item>
    <Dropdown.Item eventKey="Tunisia" value="Tunisia">Tunisia</Dropdown.Item>
    <Dropdown.Item eventKey="Turkey" value="Turkey">Turkey</Dropdown.Item>
    <Dropdown.Item eventKey="Turkmenistan" value="Turkmenistan">Turkmenistan</Dropdown.Item>
    <Dropdown.Item eventKey="Turks &amp; Caicos Is" value="Turks &amp; Caicos Is">Turks &amp; Caicos Is</Dropdown.Item>
    <Dropdown.Item eventKey="Tuvalu" value="Tuvalu">Tuvalu</Dropdown.Item>
    <Dropdown.Item eventKey="Uganda" value="Uganda">Uganda</Dropdown.Item>
    <Dropdown.Item eventKey="Ukraine" value="Ukraine">Ukraine</Dropdown.Item>
    <Dropdown.Item eventKey="United Arab Erimates" value="United Arab Erimates">United Arab Emirates</Dropdown.Item>
    <Dropdown.Item eventKey="United Kingdom" value="United Kingdom">United Kingdom</Dropdown.Item>
    <Dropdown.Item eventKey="United States of America" value="United States of America">United States of America</Dropdown.Item>
    <Dropdown.Item eventKey="Uraguay" value="Uraguay">Uruguay</Dropdown.Item>
    <Dropdown.Item eventKey="Uzbekistan" value="Uzbekistan">Uzbekistan</Dropdown.Item>
    <Dropdown.Item eventKey="Vanuatu" value="Vanuatu">Vanuatu</Dropdown.Item>
    <Dropdown.Item eventKey="Vatican City State" value="Vatican City State">Vatican City State</Dropdown.Item>
    <Dropdown.Item eventKey="Venezuela" value="Venezuela">Venezuela</Dropdown.Item>
    <Dropdown.Item eventKey="Vietnam" value="Vietnam">Vietnam</Dropdown.Item>
    <Dropdown.Item eventKey="Virgin Islands (Brit)" value="Virgin Islands (Brit)">Virgin Islands (Brit)</Dropdown.Item>
    <Dropdown.Item eventKey="Virgin Islands (USA)" value="Virgin Islands (USA)">Virgin Islands (USA)</Dropdown.Item>
    <Dropdown.Item eventKey="Wake Island" value="Wake Island">Wake Island</Dropdown.Item>
    <Dropdown.Item eventKey="Wallis &amp; Futana Is" value="Wallis &amp; Futana Is">Wallis &amp; Futana Is</Dropdown.Item>
    <Dropdown.Item eventKey="Yemen" value="Yemen">Yemen</Dropdown.Item>
    <Dropdown.Item eventKey="Yugoslavia" value="Yugoslavia">Yugoslavia</Dropdown.Item>
    <Dropdown.Item eventKey="Zaire" value="Zaire">Zaire</Dropdown.Item>
    <Dropdown.Item eventKey="Zambia" value="Zambia">Zambia</Dropdown.Item>
    <Dropdown.Item eventKey="Zimbabwe" value="Zimbabwe">Zimbabwe</Dropdown.Item>
</>
)
export default Countries